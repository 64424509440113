// Variable overrides
//@font-face {
//  font-family: SkoltechFont;
//  font-weight: 400;
//  src: url("../fonts/ALSRundgang-Regular.woff") format("woff");
//}
@font-face {
  font-family: SkoltechFont;
  font-weight: 700;
  src: url("../fonts/ALS-Ekibastuz-Bold.woff") format("woff");
}
@font-face {
  font-family: SkoltechFont;
  font-weight: 800;
  src: url("../fonts/ALSEkibastuz-Heavy.woff") format("woff");
}
//@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
$font-family-sans-serif: 'SkoltechFont', Helvetica, Arial, sans-serif;
//$font-family-sans-serif: 'SkoltechFont', 'Nunito', Helvetica, Arial, sans-serif;

$input-border-radius:                   2px;
$input-border-radius-lg:                2px;
$input-border-radius-sm:                2px;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #16161f !default;

$grid-gutter-width: 32px !default;

$resp-xs: 0;
$resp-sm: 375px;
$resp-md: 414px;
$resp-lg: 768px;
$resp-xl: 1024px;

$grid-breakpoints: (
        xs: $resp-xs,
        sm: $resp-sm,
        md: $resp-md,
        lg: $resp-lg,
        xl: $resp-xl
) !default;

$container-max-width-sm: 375px;
$container-max-width-md: 414px;
$container-max-width-lg: 768px;
$container-max-width-xl: 1024px;

$container-max-widths: (
        sm: $container-max-width-sm,
        md: $container-max-width-md,
        lg: $container-max-width-lg,
        xl: $container-max-width-xl
) !default;

$modal-xl:     450px - 32px   !default;
$modal-lg:     414px - 32px   !default;
$modal-md:     375px - 32px   !default;
$modal-sm:     320px - 32px   !default;

$toast-max-width: 375px - 32px !default;

$dropdown-item-padding-y:           .75rem !default;
$dropdown-item-padding-x:           .75rem !default;

$blue:    #5271ff;

$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

$colors: ();
$colors: map-merge(
                (
                        "blue":       $blue,
                        "indigo":     $indigo,
                        "purple":     $purple,
                        "pink":       $pink,
                        "red":        $red,
                        "orange":     $orange,
                        "yellow":     $yellow,
                        "green":      $green,
                        "teal":       $teal,
                        "cyan":       $cyan,
                        "white":      $white,
                        "gray":       $gray-600,
                        "gray-dark":  $gray-800
                ),
                $colors
);

$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: ();
$theme-colors: map-merge(
                (
                        "primary":    $primary,
                        "secondary":  $secondary,
                        "success":    $success,
                        "info":       $info,
                        "warning":    $warning,
                        "danger":     $danger,
                        "light":      $light,
                        "dark":       $dark
                ),
                $theme-colors
);

// Bootstrap and its default variables
@import "../../node_modules/bootstrap/scss/bootstrap";

// Animations
@import "../../node_modules/animate.css/animate.css";

// Phone iniput
@import "../../node_modules/react-phone-input-2/lib/plain.css";
.react-tel-input {
  .flag-dropdown {

    left: 1px;
    top: 1px;
    bottom: 1px;
    border-top: none;
    border-left: none;
    border-bottom: none;
  }

  input.form-control {
    width: 100%;
  }
}

// Custom boostrap buttons
.btn-sk-nav {
  @include button-outline-variant($white);
  display: flex;
  align-items: center;
}

//
// Custom SK scss
//

.u-100vh {
  height: 100vh;
}

.sklogo {
  padding: 16px 96px;
  background-color: $blue;
  margin: auto;

  width: 100%;
}

.sklogo-container {
  @media (max-width: $resp-md - 1) {
    max-width: 375px;
  }
  @media (min-width: $resp-md) and (max-width: $resp-lg - 1) {
    max-width: 414px;
  }
  @media (min-width: $resp-md) and (max-width: $resp-lg - 1) {
    max-width: 450px;
  }
  @media (min-width: $resp-lg) {
    max-width: 500px;
  }
}

.sk-toast-container {
  max-width: $toast-max-width;
  margin: 0 64px 0 auto;
  padding-bottom: 64px;
}

.sk-toast {
  background-color: $dark;

  &.sk-toast-danger {
    background-color: $danger;
  }

  &.sk-toast-warning {
    background-color: $warning;
  }

  &.sk-toast-success {
    background-color: $success;
  }

  @include border-radius(16px);
  box-shadow: 0 4px 16px rgba($black, .5);
  border: none;
  padding: 0 16px 0 0;

  .toast-header {
    padding: 0;
    background-color: transparent;
    border-bottom: none;
    position: absolute;
    right: 16px;
    top: 10px;

    .close {
      color: $white !important;
      text-shadow: none !important;
      opacity: .3;

      // Override <a>'s hover style
      @include hover() {
        color: $white;
        text-decoration: none;
      }

      &:not(:disabled):not(.disabled) {
        @include hover-focus() {
          opacity: .75;
        }
      }
    }
  }

  .toast-body {
    padding: 24px;
    color: $white !important;
    pre {
      display: inline;
      color: $white !important;
    }
  }

  &.sk-toast-w-header {
    padding-top: 16px;

    .toast-header {
      left: 0;
      right: 0;
      padding: 0 48px 0 24px;
      width: 100%;
      top: 16px;
      color: rgba($white, .4);
      font-weight: bold;

      .close {
        position: absolute;
        right: 16px;
      }
    }
  }
}

.sk-navigation-container {
  display: flex;
  justify-content: center;

  background-color: $blue;
}

.sk-navigation {
  @media (max-width: $resp-md - 1) {
    width: 375px;
  }
  @media (min-width: $resp-md) and (max-width: $resp-lg - 1) {
    width: 414px;
  }
  @media (min-width: $resp-md) and (max-width: $resp-lg - 1) {
    width: 450px;
  }
  @media (min-width: $resp-lg) {
    width: 1024px;
  }

  height: 80px;

  display: flex;
  align-items: center;

  padding: 0 16px;
}

.sk-navigation-brand {
  flex: 1 0 auto;
  & > img {
    max-width: 100px;
  }
}

.sk-navigation-action {
  flex: 0 0 auto;
  margin-left: 16px;
}

.sk-navigation-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;

  @media (max-width: $resp-md - 1) {
    max-width: 160px;
  }
  @media (min-width: $resp-md) and (max-width: $resp-lg - 1) {
    max-width: 180px;
  }
  @media (min-width: $resp-md) and (max-width: $resp-lg - 1) {
    max-width: 220px;
  }
  @media (min-width: $resp-lg) {
    max-width: 250px;
  }
}

.sk-navigation-menu {
  margin: 0 -1 * $grid-gutter-width/2;
}

.sk-picker {
  .card {
    margin-bottom: 16px;
    &:last-of-type {
      margin-bottom: 0;
    }

    cursor: pointer;

    transition: $transition-base;
    &:hover {
      background-color: $gray-200;
    }
  }
}

.sk-filesdialog {
  .modal-dialog {
    @media (max-width: $resp-md - 1) {
      max-width: $modal-sm;
    }
    @media (min-width: $resp-md) and (max-width: $resp-lg - 1) {
      max-width: $modal-md;
    }
    @media (min-width: $resp-md) and (max-width: $resp-lg - 1) {
      max-width: $modal-lg;
    }
    @media (min-width: $resp-lg) {
      max-width: $modal-xl;
    }
  }
}

.modal-dialog.modal-sk-sideform,
.sk-back-recommendation-view .modal-dialog {
  @media (max-width: $resp-md - 1) {
    max-width: $modal-sm;
  }
  @media (min-width: $resp-md) and (max-width: $resp-lg - 1) {
    max-width: $modal-md;
  }
  @media (min-width: $resp-md) and (max-width: $resp-lg - 1) {
    max-width: $modal-lg;
  }
  @media (min-width: $resp-lg) {
    max-width: 720px;
  }
}

.sk-status-sticky {

  @media (min-width: $resp-xl) {
    position: fixed;
    top: 0;
    height: 100vh;
  }
}

.sk-divider-header {
  padding: 12px 0;
  border-bottom: 2px solid $gray-200;
  margin-top: 32px;
  margin-bottom: 16px;
  &:first-of-type {
    margin-top: 0;
  }
}

.sk-collapse-header {
  cursor: pointer;
  padding: 12px 0;
  border-bottom: 2px solid transparent;

  display: flex;
  align-items: center !important;

  opacity: 1;
  transition: $transition-base;

  &:hover {
    opacity: .6;
    border-bottom: 2px solid $gray-400;
  }

  & > h2 {
    margin-bottom: 0;
  }

  &.sk-collapse-header-expanded {
    margin-bottom: 16px;
    border-bottom: 2px solid $gray-900;
    .sk-collapse-arrow {
      transform: rotate(90deg);
    }
  }

  .sk-collapse-arrow {
    transition: transform .2s ease-in-out;
    transform: rotate(0);
  }
}

$applications-table-side-width: 86px;

.sk-applications-table-container {
  @media (max-width: $resp-md - 1) {
    padding: 0 calc((100vw - #{$container-max-width-sm - $grid-gutter-width}) / 2);
  }
  @media (min-width: $resp-md) and (max-width: $resp-lg - 1) {
    padding: 0 calc((100vw - #{$container-max-width-md - $grid-gutter-width}) / 2);
  }
  @media (min-width: $resp-lg) {
    padding: 0 calc((100vw - #{$container-max-width-xl - $grid-gutter-width}) / 2);
  }

  overflow-x: scroll;
  overflow-y: visible;

  table {
    tr {
      th {
        padding-top: 4px;
        padding-bottom: 4px;
        white-space: nowrap;
        height: 41px;
        box-sizing: border-box;
        vertical-align: middle;
      }
      td {
        white-space: nowrap;
        height: 49px;
        box-sizing: border-box;
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: middle;
      }
      th, td {
        border-right-width: 0;
      }
    }

  }

  .sk-application-options.btn.btn-link {
    display: flex;
    margin-right: 8px;
    &:after {
      display: none;
    }
  }

  .sk-applications-table-side {
    background-color: #fff;
    width: $applications-table-side-width;
    transition: $transition-base;
  }

  &.sk-applications-table-container-stick {
    .sk-applications-table {
      margin-left: $applications-table-side-width;
    }

    .sk-applications-table-side {
      position: absolute;
      left: 0;
      top: 0;
      &.sk-applications-table-side_1 {
        box-shadow: 4px 0 16px 0 rgba(50, 50, 50, 0.10);
        margin-left: -1px;
      }
    }
  }
}

.sk-at-table-menu {
  display: flex;

  @media (max-width: $resp-xl - 1) {
    flex-wrap: wrap;
  }

  margin: 0;

  & > * {
    padding: 0;
  }
}

.sk-at-table-actions {
  white-space: nowrap;
  display: flex;

  .btn-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: $resp-xl - 1) {
    width: 100%;
  }
}

.sk-at-table-search {
  display: flex;
  flex-grow: 1;

  @media (min-width: $resp-xl) {
    margin-left: 16px;
  }

  @media (max-width: $resp-xl - 1) {
    width: 100%;
    margin-bottom: 16px;
    order: -1;
  }
}

.dropdown-scroll {
  overflow: scroll;
  max-height: 400px;
}

.applications-table-columns-dropdown {
  overflow: scroll;
  max-height: 400px;
  box-shadow: 0 4px 24px rgba($dark, .3);

  @media (min-width: $resp-lg) {
    &.show {
      display: flex !important;
    }
    flex-wrap: wrap;

    width: 700px;
    .dropdown-item {
      width: 50%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .dropdown-item.dropdown-item-full-width {
      width: 100%;
    }

    .dropdown-divider {
      height: 1px;
      background-color: #e9ecef;
      width: 700px;
      border: none;
    }
  }
}

.toast-body {
  pre {
    font-size: 24px;
    line-height: 30px;
    margin: 8px 0;
  }
}

svg {
  vertical-align: -.125em;
}

.btn {
  .spinner-border, .spinner-grow {
    vertical-align: middle;
  }
}

.sk-page {
  min-height: 100vh;
  position: relative;
  padding-bottom: calc(10vh + 32px);
}

.sk-footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  display: flex;
  background-color: $dark;
  height: 10vh;
  padding: 32px;
  justify-content: center;
  align-items: center;

  box-shadow: $dark 0 8px 20px;
}

.sk-header-light {
  margin-bottom: 32px;
}


.sk-header {
  background-color: $dark;
  box-shadow: $dark 0 -8px 20px;
  display: flex;
  margin-bottom: 32px;
  color: $white;

  a, button {
    color: $white;
    @include hover() {
      color: $gray-600;
      text-decoration: none;
    }
  }
  .nav-pills {
    a, button {
      &.nav-link {
        &.active {
          background-color: $white;
          color: $dark;
        }
      }
    }
  }

  .dropdown-menu {
    color: $white;
    background-color: $dark;
    background-clip: padding-box;
    border: none;
    @include border-radius($dropdown-border-radius);
    box-shadow: 0 .2rem 1rem rgba($black, .5) !important;

    .dropdown-item {
      color: $white;
      background-color: transparent; // For `<button>`s
      border: 0; // For `<button>`s

      @include hover-focus() {
        color: $white;
        text-decoration: none;
        background-color: $gray-700;
      }

      &.active,
      &:active {
        color: $white;
        text-decoration: none;
        background-color: $gray-700;
      }

      &.disabled,
      &:disabled {
        color: $dropdown-link-disabled-color;
        pointer-events: none;
        background-color: transparent;
      }
    }

    .dropdown-header {
      color: $white;
      font-weight: bold;
    }

    .dropdown-divider {
      border-top: solid 1px $gray-600;
    }
  }

  .sk-username {
    color: mix($info,$white,30);
  }
}


.dropdown.dropdown-autosuggestion {
  .dropdown-menu {
    width: 100%;
    .dropdown-item {
      padding: 0.5rem 1rem;
    }
  }
}

date-input-polyfill {
  span {
    padding: .25rem;
    select, select:first-of-type {
      border: none;
      box-shadow: none;
      padding: 0 8px;
      &.monthSelect {
        border-radius: 0;
      }
    }
    height: auto;
  }
  button {
    padding: .5rem;
    border: none !important;
    &:hover {
      border: none;
    }
    height: auto !important;
  }
  z-index: 2000 !important;
}

pre {
  display: inline-block;
  vertical-align: baseline;
  background-color: $gray-100;
  padding: 4px 8px;
  border-radius: 2px;
  font-family: $font-family-monospace;
  border: 1px solid $gray-200;
}

.modal-body pre {
  margin: 0;
  vertical-align: middle;
}

@keyframes spin {
  100% {
    transform:rotate(360deg);
  }
}

.animation-spin {
  animation:spin 1s linear infinite;
}

@keyframes opacity {
  0% {
    opacity: .2;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: .2;
  }
}

.card-loading-animation {
  transition: opacity .1s linear;
}

.card-loading {
  position: relative;
  &:after {
    content: "";
    display: block;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  animation: opacity 1s ease-in-out infinite;
}

//
// Tabs
//

.nav-tabs {
  border-bottom: none;

  .nav-item {
    padding: 12px 0 8px;
    margin: 0 16px 16px 0;
    font-size: 24px;
    outline: none;
  }

  .nav-link {
    color: $gray-500;
    border: none;
    border-bottom: 2px solid transparent;

    @include border-top-radius($nav-tabs-border-radius);

    @include hover-focus() {
      border-color: $nav-tabs-link-hover-border-color;
    }

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $nav-tabs-link-active-color;

    border: none;
    border-bottom: 2px solid $black;
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}

.sk-logs-container {
  .card {
    font-size: 14px;

    .card-body {
      padding: 8px 12px;
    }

    .card-text {
      margin: 0 0 4px 0;
    }

    .sk-log-heading {
      color: $gray-500;
    }
  }
}

.sk-disabled-loading {
  & > * {
    opacity: 1;
    animation: opacity 1s ease-in-out infinite;
    pointer-events: none;
  }
}

@import "../../node_modules/swiper/swiper.min.css";
@import "../../node_modules/swiper/components/pagination/pagination.min.css";
@import "../../node_modules/swiper/components/navigation/navigation.min.css";

.swiper-tour {
  background: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: $zindex-tooltip + 10;

  box-shadow: 0 8px 256px 0 rgba(0,0,0,.7) inset;
}

.swiper-tour-container {
  width: 100%;
  height: 100%;

  * > img {
    display: block;

    height: 90vh;
    width: auto;
    margin: 5vh auto;

    @media (max-width: $resp-lg) {
      height: auto;
      width: 80vw;
      margin: 10vh auto 20vh;
    }

    @media (max-width: $resp-lg) {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,.2);
    }

    box-shadow: 0 8px 128px 0 rgba(0,0,0,.5), 0 8px 16px 0 rgba(0,0,0,.5);
  }

  .swiper-button-prev, .swiper-button-next {
    color: white !important;
    width: 50vw;
    height: 100vh;
    top: 0;
  }
  .swiper-pagination-progressbar-fill {
    background-color: $blue;
  }

  .swiper-button-prev {
    left: 0;
    padding-left: 12px;
    justify-content: flex-start;
  }
  .swiper-button-next {
    right: 0;
    padding-right: 12px;
    justify-content: flex-end;
  }

  @media (max-width: $resp-lg) {
    .swiper-button-prev, .swiper-button-next {
      color: white !important;
      width: 10vw;
      height: 100vh;
      top: 0;
    }
  }

  @media (max-width: $resp-lg) {
    .swiper-button-prev, .swiper-button-next {
      &:after {
        font-size: 20px !important;
      }
    }
  }
}

.swiper-tour-close {
  cursor: pointer;
}

.swiper-tour-skip {
  font-size: 32px;
  color: $white;
  position: absolute;
  right: 12px;
  top: 16px;
  cursor: pointer;
  z-index: $zindex-tooltip + 20;

  a {
    color: $white;
    text-decoration: none;
    &:hover {
      opacity: .8;
    }

    @media (max-width: $resp-lg) {
      span {
        display: none;
      }
    }
  }
}

.bg-disabled {
  background-color: $gray-300 !important;
}

.footer {
  text-align: center;
  border-top: 1px solid #ced4da;
  padding-top: 1em;
}

.footer a {
  color: #6c757d !important;
}
